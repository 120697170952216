<template>
  <div class="contentTitle">
    <h3>FAQs</h3>
    <button
      class="btn"
      v-if="canCreate.includes(getUserProfile.role.id)"
      @click.prevent="create = true"
    >
      New FAQ
    </button>
  </div>
  <div class="contentMenu">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" href="#">Frequently Asked Questions</a>
      </li>
    </ul>
    <div class="search-filters">
      <div class="nav-item">
        <div class="siteSearch">
          <div class="searchPan">
            <input
              type="text"
              class="form-control"
              v-model="search_key"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="users-list">
    <div class="table-head">
      <div class="main-items">
        <span>Question</span>
        <span>Last Edit</span>
      </div>
    </div>
    <template v-if="entries.length && loaded">
      <template v-for="(entry, index) in filteredEntries" :key="index">
        <faq
          :faq="entry"
          :allChecked="isChecked"
          :canEdit="canCreate.includes(getUserProfile.role.id)"
          @updateTicket="getData(currentPage, `update${entry.ticket_id}`)"
        />
      </template>
    </template>
    <template v-else-if="!entries.length && loaded">
      <span class="empty">
        There are currently no faqs created for this category.
      </span>
    </template>
    <template v-else>
      <div class="loading">
        <span class="loader"></span>
      </div>
    </template>
  </section>
  <div v-if="entries.length" class="pagination" ref="pagination">
    <span
      >Showing
      <b
        >{{ currentPage === 1 ? 1 : currentPage - 1 + "1" }}-{{
          currentTotal
        }}</b
      >
      from <b>{{ total }}</b></span
    >
    <img
      src="/assets/images/left-arrow.svg"
      alt="next_page"
      @click="handlePage('left')"
    />
    <img
      src="/assets/images/right-arrow.svg"
      alt="previous_page"
      @click="handlePage('right')"
    />
  </div>
  <CreateModal
    v-if="create"
    :items="toCreate"
    :response="response"
    @data="handleCreateData"
    @close="create = false"
  />
</template>

<script>
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import { mapMutations, mapActions, mapGetters } from "vuex";
import axiosInstance from "@/services/AxiosTokenInstance";
import { CREATE_FAQ_ACTION } from "@/store/storeconstants";
import Faq from "./FAQ.vue";
import CreateModal from "@/components/Create/Index.vue";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data: function () {
    return {
      timeout: null,
      create: false,
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      fullEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      isChecked: false,
      search_key: "",
      toTitle: "Add FAQ",
      loaded: false,
      title: "FAQs",
      canCreate: [4, 5, 6, 7, 8],
      response: [],
    };
  },
  components: {
    Faq,
    CreateModal,
  },
  watch: {
    entries(val) {
      this.filteredEntries = val;
    },
    search_key() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.getData(1);
      }, 1000);
    },
  },
  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },
    toFilter() {
      return [
        {
          name: "status",
          options: [{ name: "Active" }, { name: "Inactive" }],
        },
      ];
    },
    toCreate() {
      return [
        {
          type: "text",
          label: "Title",
          name: 'title',
          required: true
        },
        {
          type: "texteditor",
          label: "Answer",
          name: 'answer',
          required: true
        },
      ];
    },
  },
  methods: {
    handlePage(to) {
      switch (to) {
        case "left":
          if (this.currentPage > 1) this.currentPage -= 1;
          break;
        case "right":
          if (this.currentPage < this.pages) this.currentPage += 1;
          break;
      }
    },
    ...mapActions("faq", {
      createNewFaq: CREATE_FAQ_ACTION,
    }),
    ...mapMutations({
      pathing: "pathing/setPathing",
    }),
    async getData(value) {
      this.loaded = false;
      this.currentPage = value;
      let orgDataString = localStorage.getItem("organization");
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response = await axiosInstance
          .get(
            `faq?org_id=${orgData.id}&category_id=${this.$route.params.catid}&searchKey=${this.search_key}&page=${value}`
          )
          .finally(() => (this.loaded = true));
        let responseData = response.data.data;
        this.entries = responseData.data;
        this.total = responseData.total;
        const categoryName = responseData.data.length
          ? responseData.data[0].category.name
          : "";
        this.pathing([
          { name: "FAQs", link: "/equipment/faqs" },
          {
            name: categoryName,
            link: `equipment/faq-list/${this.$route.params.catid}`,
          },
        ]);
      }
    },

    async handleCreateData(data) {
      let orgData = JSON.parse(localStorage.getItem("organization"));
      let formData = {
        title: data.title,
        description: data.answer,
        category_id: this.$route.params.catid,
        organization_id: orgData.id,
      };

      const response = await axiosInstance.post("faq", formData);
      
      if (response.status === 200) {
        this.response = [true, true];
        this.emitter.emit("alert", response.data.message);
        this.getData(1);
      }else {
        this.response = [true, false];
        this.emitter.emit("alert", "Something went wrong");
      }
    },
  },
  mounted() {
    this.currentPage = 1;
    this.getData(this.currentPage);
  },
};
</script>

<style lang="scss" scoped>
.contentMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-filters {
    display: flex;
    gap: 20px;
    align-items: center;
    .searchPan {
      position: relative;
      display: flex;
      align-items: center;
      .form-control {
        padding: 10px 30px;
        border: 1px solid #ced4da;
        border-radius: 5px;
        &::placeholder {
          color: #cad6e2;
        }
      }
    }
  }
}

.users-list {
  height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  .table-head {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 10px 25px;
    box-shadow: 0px 1px 6px #cad6e280;
    border-bottom: solid 1px #f2f5f8;
    background: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    .main-items {
      width: 98%;
      display: flex;
      span {
        width: calc(93% / 2);
        display: flex;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  gap: 5px;
  margin-bottom: 10px;

  img {
    width: 8px;
    cursor: pointer;
  }

  .underline {
    text-decoration: underline;
  }
}

.empty {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-weight: 500;
}

.loading {
  height: 500px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>